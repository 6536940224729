import { fetchJson } from '~/lib/fetch';
import { ESBROWSE_URL } from './constants';
import { validBrowseParameters } from './params';

type DealAttributes = {
  slug: string;
  type: string;
  type_options: {
    multiple: boolean;
  };
};

type Filters = {
  maximum_results?: number;
  sort?: string;
  status?: string;
  query?: string;
  type?: string;
  marketplace?: boolean;
  campaign?: string;
  price_type?: string;
  price_from?: number;
  price_to?: number;
  after?: string;
  attributes?: Record<string, any>;
};

type BrowseParams = {
  slug: string[];
  dealAttributes?: DealAttributes[];
  query?: Record<string, string>;
};

const validSortParams = [
  'recommended',
  'latest',
  'review_count',
  'rating',
  'price_low',
  'price_high',
  'ending',
  'quantity_remaining',
];

const validStatusParams = ['current', 'ending', 'expired', 'all'];

const validTypeParams = [
  'all',
  'lifetime-deal',
  'limited-license',
  'freebie',
  'subscription',
  'digital-download',
];

export function getEsBrowseUrl(
  filters: Filters,
  profileParams: Record<string, string | number | boolean | undefined> = {},
  perPage = 20,
  userId?: number,
): string {
  const { attributes = {} } = filters;
  const requestParams: Record<string, string | number | boolean> = {
    __cache: 'a',
    include_aggs: 'true',
    status: filters.query ? 'all' : 'current',
    sort: 'recommended',
    per_page: perPage,
  };

  if (userId) {
    requestParams.__cache = userId;
  }

  if (filters.maximum_results) {
    requestParams.maximum_results = filters.maximum_results;
  }

  // process base parameters
  if (filters.sort && validSortParams.includes(filters.sort)) {
    requestParams.sort = filters.sort;
  }

  if (filters.status && validStatusParams.includes(filters.status)) {
    requestParams.status = filters.status;
  }

  if (filters.query) {
    requestParams.query = filters.query;
    requestParams.trending_fallback_count = 8;
  }

  if (
    filters.type &&
    validTypeParams.includes(filters.type) &&
    filters.type !== 'all'
  ) {
    requestParams.type = filters.type;
  }

  if (typeof filters.marketplace !== 'undefined') {
    requestParams.marketplace = String(filters.marketplace);
  }

  // Generic campaign participation
  if (filters.campaign) {
    requestParams['participation[in]'] = filters.campaign;
  }

  if (filters.price_type === 'free') {
    requestParams['default_price[lte]'] = 0;
  } else {
    if (typeof filters.price_from !== 'undefined') {
      requestParams['default_price[gte]'] = filters.price_from;
    }
    if (typeof filters.price_to !== 'undefined') {
      requestParams['default_price[lt]'] = filters.price_to;
    }
  }

  if (filters.after) {
    requestParams.search_after = filters.after;
  }

  // append any attributes
  Object.keys(attributes).forEach((key) => {
    if (['collectionSlug', 'templateSlug'].includes(key)) {
      return;
    }

    const value = attributes[key];
    if (Array.isArray(value)) {
      // make sure empty arrays are omited
      if (value.length > 0) {
        requestParams[`attribute[${key}]`] = value.join(',');
      }
    } else if (value) {
      requestParams[`attribute[${key}]`] = value;
    }
  });

  if (profileParams) {
    Object.keys(profileParams).forEach((key) => {
      const value = profileParams[key];

      if (value) {
        // special handler for marketplace
        if (key === 'marketplace') {
          requestParams.marketplace = String(value);
        } else if (validBrowseParameters.includes(key)) {
          // don't overwrite sort parameter if changed by user
          if (key !== 'sort' || (key === 'sort' && !filters.sort)) {
            requestParams[key] = value;
          }
        } else {
          requestParams[`profile{${key}}`] = value;
        }
      }
    });
  }

  const requestString = Object.keys(requestParams)
    .map((key) => `${key}=${encodeURIComponent(requestParams[key])}`)
    .join('&');

  return `${ESBROWSE_URL}?${requestString}`;
}

export function getDeals(
  filters: Filters,
  profileParams: Record<string, string> = {},
  perPage = 20,
): Promise<any> {
  const url = getEsBrowseUrl(filters, profileParams, perPage);
  return fetchJson(url);
}

export function extractBrowseParameters({
  slug,
  dealAttributes = [],
  query = {},
}: BrowseParams): {
  slugs: string[];
  filters: Record<string, string>;
  attributes: Record<string, any>;
} {
  const attributes = {} as Record<string, string | number | boolean | string[]>;
  let searchParams = {} as Record<string, string>;
  const slugs = [] as string[];

  Object.keys(query).forEach((key) => {
    if (key !== 'slug') {
      searchParams[key] = query[key];
    }
  });

  slug.forEach((value, index) => {
    if (value.substr(0, 2) === '!!') {
      const qs = value.substr(2);

      if (qs) {
        searchParams = qs
          .split('&')
          .reduce((acc: Record<string, string>, cur) => {
            const [key, value] = cur.split('=');
            // decodeURIComponent doesn't handle + as space for the query params
            acc[key] = decodeURIComponent(value)?.replaceAll('+', ' ');
            return acc;
          }, {});
      }
    } else if (!(index === 0 && ['browse', 'search'].includes(value))) {
      slugs.push(value);
    }
  });

  // turn out query string into an applied filter object
  const filters = {} as Record<string, string>;
  Object.keys(searchParams).forEach((key) => {
    const attr = dealAttributes.find((attr) => attr.slug === key);

    if (attr) {
      if (attr.type === 'boolean') {
        attributes[key] = searchParams[key].toLowerCase() === 'true';
      } else if (attr.type === 'enumeration') {
        // TODO: validate options
        if (attr.type_options.multiple === true) {
          attributes[key] = searchParams[key].split(',');
        } else {
          attributes[key] = searchParams[key];
        }
      }
    } else if (validBrowseParameters.includes(key)) {
      if (key === 'select') {
        if (String(searchParams[key]) === 'true') {
          filters.marketplace = 'false';
        }
      } else {
        filters[key] = searchParams[key];
      }
    }
  });

  return {
    slugs,
    filters,
    attributes,
  };
}

export function extractBrowse({
  slug,
  dealAttributes = [],
  query = {},
}: BrowseParams): Filters & {
  attributes: Record<string, any>;
} {
  const {
    slugs,
    filters = {},
    attributes = {},
  } = extractBrowseParameters({
    slug,
    dealAttributes,
    query,
  });

  if (slugs.length > 0) {
    // validate group
    const group: any = dealAttributes.find(
      (dealAttribute) => dealAttribute.slug === 'group',
    );
    if (
      slugs[0] === 'courses-more' ||
      (group &&
        group.type_options.options.some(
          (option: any) => option.value === slugs[0],
        ))
    ) {
      attributes.group = slugs[0];
    } else {
      throw new Error('group not found');
    }
  }

  if (slugs.length > 1) {
    // validate category
    const category: any = dealAttributes.find(
      (dealAttribute) => dealAttribute.slug === 'category',
    );
    if (
      category &&
      category.type_options.options.some(
        (option: any) => option.value === slugs[1],
      )
    ) {
      attributes.category = slugs[1];
    } else {
      throw new Error('category not found');
    }
  }

  if (slugs.length > 2) {
    // validate subcategory
    const subCategory: any = dealAttributes.find(
      (dealAttribute) => dealAttribute.slug === 'subcategory',
    );
    if (
      subCategory &&
      subCategory.type_options.options.some(
        (option: any) => option.value === slugs[2],
      )
    ) {
      attributes.subcategory = slugs[2];
    } else {
      throw new Error('subcategory not found');
    }
  }

  return {
    ...filters,
    attributes,
  };
}

export function extractCollection({
  slug,
  dealAttributes = [],
  query = {},
}: BrowseParams): Filters & { attributes: Record<string, any> } {
  const {
    slugs,
    filters = {},
    attributes = {},
  } = extractBrowseParameters({
    slug,
    dealAttributes,
    query,
  });

  if (slugs.length > 0) {
    attributes.collectionSlug = slugs[0];
  }

  if (slugs.length > 1) {
    attributes.templateSlug = slugs[1];
  }

  return {
    ...filters,
    attributes,
  };
}
