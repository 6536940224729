import { createHash } from 'crypto';
import { GetServerSidePropsContext } from 'next';
import { RefObject } from 'react';

import { MAX_6_CHAR_INT, MIN_7_CHAR_INT, ShareType } from '~/constants/global';
import { StatusError } from '~/lib/fetch';
import { UserAgent, UtmParamObject } from '~/types/lib/util';
import { SessionObjectProps } from '~/types/user';
import { DEFAULT_PLUS_DISCOUNT_PERCENTAGE, EMAIL_REGEXP } from './constants';

interface Cookie {
  key: string;
  value: string;
}

export const isValidEmail = (email: string): boolean =>
  EMAIL_REGEXP.test(String(email).toLowerCase());

export const getCSRFCookie = (): string => {
  if (!process.browser) {
    return '';
  }

  const cookie = document.cookie
    .split(';')
    .map(
      (c): Cookie => ({
        key: c.split('=')?.[0]?.trim(),
        value: c.split('=')?.[1]?.trim(),
      }),
    )
    .find((c): boolean => c.key === 'csrftoken');

  return cookie ? cookie.value : '';
};

/**
 * @param text The HTML string to parse
 * @param maxLength The maximum length of the returned string
 * @param minLength The minimum length of the returned string
 *
 * @returns The first sentence of the given text
 */
export const firstSentence = ({
  text,
  maxLength = 60,
  minLength = 8,
  showEllipsis = false,
}: {
  text: string;
  maxLength?: number;
  minLength?: number;
  showEllipsis?: boolean;
}): string => {
  if (text.length <= minLength) {
    return text;
  }

  // find first sentence with a minimum of minLength chars
  for (
    let i = Math.min(text.length, minLength);
    i < Math.min(text.length, maxLength);
    i += 1
  ) {
    const c = text.charAt(i);

    if (['?', '.', '!'].includes(c)) {
      return text.substr(0, i + 1);
    }
  }

  // no separator found, return full string
  if (text.length < maxLength) {
    return text;
  }

  // no sentence found, stop at last word prior to maxLength
  const i = text.lastIndexOf(' ', maxLength);
  if (i > 0) {
    return text.substr(0, i) + (showEllipsis ? '...' : '');
  }

  // last resort just trim maxLength
  return text.substr(0, maxLength) + (showEllipsis ? '...' : '');
};

/**
 * (Ported over from appsumo-2)
 * Find last ' ' within maxLength and return substring including last word (excluding the ' ')
 *
 * @param str The HTML string to parse
 * @param maxLength The maximum length of the returned string
 *
 * @returns substring of str with maxLength of 160
 */
export const lastWordAt = (str: string, maxLength: number = 160) => {
  if (str.length > maxLength) {
    const i = str.lastIndexOf(' ', maxLength);
    if (i > 0) {
      return str.substr(0, i);
    }
  }

  return str.substr(0, maxLength);
};

/**
 * (port over from appsumo-2)
 * Ensure string is sentence case
 *
 * @param str The string to convert to sentence case
 *
 * @returns The string in sentence case
 */
export const sentenceCase = (str: string) => {
  const newString = str
    .toLowerCase()
    .replace(/(^\s*\w|[.!?]\s*\w)/g, (c) => c.toUpperCase());
  return newString;
};

/**
 * Get UTM params for social share modal.
 * @param {Object} data - Deal or wishlist data
 * @param {String} data.slug - Deal or wishlist slug
 * @param {Number} data.id - Deal or wishlist id
 * @param {Number} userId - Id of the logged in user
 * @returns {Object} An object with the social share utm params
 */
export const getUtmParamsForSocialShare = ({
  data,
  userId,
  shareType = ShareType.PDP,
  content,
}: {
  data: { slug: string; id: number };
  userId: number | undefined;
  shareType?: ShareType;
  content?: string;
}) => {
  let utmString = '';
  switch (shareType) {
    case ShareType.POST_PURCHASE:
      // if given content is null, use the deal id as the content
      utmString = getPostPurchaseShareUtmString(
        content ?? data?.id.toString(),
        userId,
      );
      break;
    case ShareType.REDEMPTION:
      utmString = getRedemptionShareUtmString(data.id, userId);
      break;
    case ShareType.PDP:
      utmString = getShareUtmString({
        source: 'pdpshare',
        content: data.id.toString(),
        medium: 'share',
        campaign: userId,
      });
      break;
    case ShareType.ALTERNATIVE_TO:
      utmString = getShareUtmString({
        source: 'alternativeshare',
        content: data.id.toString(),
        medium: 'share',
        campaign: userId,
      });
      break;
    // leaving default as the original implementation
    default: {
      const utmMedium = 'utm_medium=social';
      const getUtmSource = (social: string) =>
        `utm_source=${data.slug}-share-${social}`;
      const getUtmCampaign = (social: string) => {
        const userIdString = userId ? `-${userId}` : '';
        return `utm_campaign=${data.slug}-share-${social}-${data.id}${userIdString}`;
      };

      return {
        text: `?${getUtmSource('link')}&${utmMedium}&${getUtmCampaign('link')}`,
        email: `?${getUtmSource('email')}&${utmMedium}&${getUtmCampaign(
          'email',
        )}`,
        facebook: `?${getUtmSource('facebook')}&${utmMedium}&${getUtmCampaign(
          'facebook',
        )}`,
        linkedin: `?${getUtmSource('linkedin')}&${utmMedium}&${getUtmCampaign(
          'linkedin',
        )}`,
        twitter: `?${getUtmSource('twitter')}&${utmMedium}&${getUtmCampaign(
          'twitter',
        )}`,
      };
    }
  }

  return {
    text: utmString,
    email: utmString,
    facebook: utmString,
    linkedin: utmString,
    twitter: utmString,
  };
};

/**
 * Get UTM string for post purchase referral tracking.
 * @param {Number} userId - Id of the logged in user
 * @returns {String} A string with the utm params required to track referrals
 */
export const getPostPurchaseShareUtmString = (
  content?: string,
  userId?: number,
) => {
  return getShareUtmString({
    source: 'confirmationshare',
    medium: 'share',
    campaign: userId?.toString(),
    content,
  });
};

/**
 * Get UTM string for referral page social share tracking.
 * @param {Number} dealId - Id of the deal
 * @param {Number} userId - Id of the logged in user
 * @returns {String} A string with the utm params required to track referrals
 */
export const getRedemptionShareUtmString = (
  dealId: number,
  userId?: number,
) => {
  return getShareUtmString({
    source: 'redemptionshare',
    content: dealId.toString(),
    medium: 'share',
    campaign: userId?.toString(),
  });
};

/**
 * Get UTM string for any social tracking
 * @param {UtmParamObject} utmParams - The utm parameters to include in the share url
 * @returns {String} The utm string to share
 */
export const getShareUtmString = (utmParams: UtmParamObject): string => {
  // filter for non-empty values, then format the string
  const paramString = Object.entries(utmParams)
    .filter(([, value]) => !!value)
    .map(([key, value]) => `utm_${key}=${value}`)
    .join('&');
  return `?${paramString}`;
};

/**
 * Open a new window with the given url and parameters
 * @param {String} url - The url to open
 * @param {String} windowName - The name of the window
 * @param {Boolean} popup - Whether to open the window as a popup
 * @param {Number} height - The height of the window
 * @param {Number} width - The width of the window
 * @returns {void}
 */
export const openExternalWindow = ({
  url,
  windowName,
  popup,
  height = 600,
  width = 600,
}: {
  url: string;
  windowName: string;
  popup: boolean;
  height?: number;
  width?: number;
}): void => {
  if (typeof window === 'undefined') {
    return;
  }

  const left = (window.innerWidth - width) / 2;
  const top = (window.innerHeight - height) / 2;
  const windowFeatures = [
    `left=${left}`,
    `top=${top}`,
    `width=${width}`,
    `height=${height}`,
    `popup=${popup}`,
  ].join(',');
  window.open(url, windowName, windowFeatures);
};
// Useful for hidden fields with string values
export const isTrue = (value: string) => value.toLowerCase() === 'true';
export const isFalse = (value: string) => value.toLowerCase() === 'false';
export const getPlusDiscount = (session?: SessionObjectProps) =>
  `${session?.plus_percentage_off ?? DEFAULT_PLUS_DISCOUNT_PERCENTAGE}`;

export const getCSSRGBValues = (rgb: string): string[] =>
  rgb.replace(/[^\d,]/g, '').split(',');

const componentToHex = (value: number | string) => {
  const hex = Number(value).toString(16);
  return hex.length == 1 ? '0' + hex : hex;
};

export const rgbToHex = (
  red: string | number,
  green: string | number,
  blue: string | number,
): string => {
  return `#${componentToHex(red)}${componentToHex(green)}${componentToHex(
    blue,
  )}`;
};

/**
 * Make given color darker or lighter by given magnitude
 * @param {String} hexColor - The color to make darker or lighter
 * @param {Number} percent - The percent to make the color darker or lighter (Positive for light, negative for dark)
 * @returns {String} The darker or lighter color
 *
 * More info
 * https://css-tricks.com/snippets/javascript/lighten-darken-color/
 * https://gist.github.com/renancouto/4675192?permalink_comment_id=2976324#gistcomment-2976324
 */
export const colorNewShade = (hexColor: string, percent: number): string => {
  const num = parseInt(hexColor.replace('#', ''), 16);
  const magnitude = Math.round(2.55 * percent);

  let red = (num >> 16) + magnitude;
  if (red > 255) red = 255;
  if (red < 0) red = 0;

  let blue = ((num >> 8) & 0x00ff) + magnitude;
  if (blue > 255) blue = 255;
  if (blue < 0) blue = 0;

  let green = (num & 0x0000ff) + magnitude;
  if (green > 255) green = 255;
  if (green < 0) green = 0;

  return (
    '#' +
    (0x1000000 + red * 0x10000 + blue * 0x100 + green).toString(16).slice(1)
  );
};

const getRGB = (value: string): number => parseInt(value, 16) || Number(value);

function getsRGB(value: string): number {
  return getRGB(value) / 255 <= 0.03928
    ? getRGB(value) / 255 / 12.92
    : Math.pow((getRGB(value) / 255 + 0.055) / 1.055, 2.4);
}

function getLuminance(hexColor: string): number {
  return (
    0.2126 * getsRGB(hexColor.substring(1, 3)) +
    0.7152 * getsRGB(hexColor.substring(3, 5)) +
    0.0722 * getsRGB(hexColor.substring(hexColor.length - 2))
  );
}

function getContrast(firstColor: string, secondColor: string): number {
  const L1 = getLuminance(firstColor);
  const L2 = getLuminance(secondColor);

  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
}

/**
 * Get the tailwind text color class contrast based on the given background color
 * @param {String} backgroundHexColor - The background color
 * @returns {String} The contrast text color
 *
 * More info: https://wunnle.com/dynamic-text-color-based-on-background
 */
export const getTailwindContrastTextClass = (
  backgroundHexColor: string,
): string => {
  const whiteContrast = getContrast(backgroundHexColor, '#ffffff');
  const blackContrast = getContrast(backgroundHexColor, '#000000');

  return whiteContrast > blackContrast ? 'text-white' : 'text-black-pearl';
};

export const getCookieString = (
  req: GetServerSidePropsContext['req'],
): string => {
  if (!req || !req.cookies) {
    return '';
  }
  const cookieString = Object.keys(req.cookies)
    .map((key) => `${key}=${req.cookies[key]}`)
    .join('; ');
  return cookieString;
};

/**
 * Get the hash value from the given path.
 * @param {String} path - The path to get the hash value from
 * @returns {String} The hash value
 */
export const getHashFromPath = (path: string): string => {
  if (path.indexOf('#') > path.indexOf('?')) {
    return path.split('#')[1] || '';
  }

  const parts = path.split('?');
  const hashPart = parts[0].split('#');

  return hashPart[1] || '';
};

/**
 * Get the query parameters from the given path value.
 * @param {String} path - The path value to get the query parameters from
 * @returns {URLSearchParams} The query parameters
 */
export const getQueryFromPath = (path: string): URLSearchParams => {
  if (path.indexOf('?') > path.indexOf('#')) {
    return new URLSearchParams(path.split('?')[1] || '');
  }

  const parts = path.split('#')[0];
  const queryString = parts.split('?')[1];

  return new URLSearchParams(queryString);
};

/**
 * Generate range of numbers from min to max. Fill in missing numbers.
 * @param {Number} min - The minimum number
 * @param {Number} max - The maximum number
 * @returns {Array<Number>} The range of numbers
 */
export function range(min: number, max: number): Array<number> {
  return Array.from({ length: max - min + 1 }, (_, i) => min + i);
}

/**
 * Format date to human readable format. For example, 2021-01-01 to January 1, 2021
 *
 * @param {String} inputDate - The date to format
 * @param {Object} options - The options to pass to the toLocaleDateString function
 * @returns {String} The formatted date
 */
export function formatToHumanReadableDate(
  inputDate: string,
  options?: Intl.DateTimeFormatOptions,
  locale?: string,
) {
  const customLocale =
    locale ?? (typeof navigator !== 'undefined' ? navigator.language : 'en-US');

  const formattedDate = new Date(inputDate)?.toLocaleDateString(customLocale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    ...options,
  });

  return formattedDate;
}

/**
 * Format date to human readable format. For example, 2025-01-03T16:32:06Z to 01/03/2025
 *
 * @param {String} inputDate - The date to format
 * @returns {String} The formatted date
 */
export function formatStandardCalendarDate(inputDate: string) {
  if (!inputDate) return;

  const locale =
    typeof navigator !== 'undefined' ? navigator.language : 'en-US';
  const formattedDate = new Date(inputDate)?.toLocaleDateString(locale, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });

  return formattedDate;
}

export const manuallyClickRef = (ref: RefObject<HTMLInputElement>) => {
  if (ref.current) {
    ref.current.click();
  }
};

export const setFormErrorsFromResponse = async (
  formEl: any,
  error: StatusError,
) => {
  if (error.response?.status === 400) {
    const errors = await error.response.json();

    Object.entries(errors).forEach(([field, error]) => {
      formEl.current?.setError(field, {
        type: 'manual',
        message: error,
      });
    });
  }
};

export const isExperimentalVariant = (variant?: string) => {
  if (!variant) return false;
  return !['control', 'disabled'].includes(variant);
};

// Parse user agent and return a string with the OS and device type in a more human readable format
export const parseUserAgent = (userAgentData: UserAgent): string => {
  try {
    const {
      os: { name },
      device: { type = '' },
    } = userAgentData;

    if (!name) return '';

    let os = name.toLowerCase();
    let deviceType = type.toLowerCase();

    // If not mobile or tablet, default to desktop
    if (deviceType !== 'mobile' && deviceType !== 'tablet') {
      deviceType = 'desktop';
    }

    // Use "includes" since the OS can be more specific than just 'mac' or 'windows'
    if (os.includes('mac')) {
      os = 'mac';
    } else if (os.includes('ios')) {
      os = 'ios';
    } else if (os.includes('android')) {
      os = 'android';
    } else if (os.includes('windows')) {
      os = 'windows';
    } else if (os.includes('linux')) {
      os = 'linux';
    }

    return `${os}-${deviceType}`;
  } catch (e) {
    return '';
  }
};

export const highlightText = (text: string, query: string) => {
  return text.replace(
    new RegExp(query, 'gi'),
    (match: string) => `<mark>${match}</mark>`,
  );
};

/**
 * As of 5/28/24, temporarily hiding free tier of TidyCal since marketing is running paid traffic
 * there and it's impacting affiliate payouts. Visually hiding free tiers on PDP as a temp fix
 *
 * @param {Number} dealId - the deal id
 * @returns {Boolean} - whether we should hide the free tier
 */
export const isTidyCal = (slug: string) => {
  return slug === 'tidycal';
};

/**
 * Lightweight 'pluralize' function that returns the singular or plural form of a word based on the count
 * Could use a package, but this isn't being used in enough places and is simple enough to implement
 *
 * @param {Number} count - the count of the items
 * @param {String} singular - the singular form of the word
 * @param {String} suffix - the suffix to add to the plural form of the word
 */
export const pluralize = (
  count: number,
  singular: string,
  suffix: string = 's',
): string => {
  if (typeof singular !== 'string' || typeof suffix !== 'string') {
    throw new Error('singular and suffix must be strings');
  }
  if (typeof count !== 'number') {
    throw new Error('count must be a number');
  }
  if (count < 0) {
    throw new Error('count must be a positive number');
  }
  return count === 1 ? singular : singular + suffix;
};

/**
 * Parse a date string -> JS Date
 * @param {String} dateString - a date string
 * @returns {Date} - the parsed date
 */
export const parseDate = (dateString?: string | null): Date | null => {
  return dateString ? new Date(dateString) : null;
};

/**
 * Calculate the number of days until a given date (round to the nearest day)
 * @param {Date} targetDate - the date in question
 * @returns {Number} - the number of days until the target date
 */
export const getDaysUntilDate = (targetDate?: Date | null): number => {
  if (!targetDate) return 0;

  const currentDate = new Date();
  const timeDifference = targetDate.getTime() - currentDate.getTime();
  const daysInMilliseconds = 24 * 60 * 60 * 1000;
  const daysRemaining = timeDifference / daysInMilliseconds;
  return daysRemaining < 1 ? 0 : Math.round(daysRemaining);
};

/**
 * Return a seeded random number generator
 * https://github.com/bryc/code/blob/master/jshash/PRNGs.md
 *
 * @param {Number} a seed value
 * @param {Number} b seed value
 * @param {Number} c seed value
 * @param {Number} d seed value
 */
export function sfc32(
  a: number,
  b: number = 0,
  c: number = 0,
  d: number = 0,
): Function {
  return function () {
    // bitwise OR assignment with 0 to ensure integer
    a |= 0;
    b |= 0;
    c |= 0;
    d |= 0;
    const t = (((a + b) | 0) + d) | 0;
    d = (d + 1) | 0;
    a = b ^ (b >>> 9);
    b = (c + (c << 3)) | 0;
    // bitwise left shift with 21 and right shift with 11
    c = (c << 21) | (c >>> 11);
    c = (c + t) | 0;

    // 4294967296 - is the magic number for 2^32
    return (t >>> 0) / 4294967296;
  };
}

/**
 * Generate a random integer between min and max using a seeded random number generator
 *
 * Sends the seed value through a sin function to ensure our seed value can fit into a 32 bit integer
 * utilizes the sfc32 function to generate a random number between 0 and 1
 * then multiplies by the range and adds the min value to ensure the number is between min and max
 *
 * @param {Number} min - the minimum value
 * @param {Number} max - the maximum value
 * @param {Number} seed - the seed value
 */
export function getRandomIntFromSeed(
  min: number = 0,
  max: number = 1,
  seed: number,
): number {
  // sin function to ensure the seed is between 0 and 1
  // multiply by (2^32) to ensure seed can be represended as a 32 bit integer
  let modifiedSeed = Math.sin(seed) * 4294967296;
  const rng = sfc32(modifiedSeed, modifiedSeed++);
  return Math.floor(rng() * (max - min + 1)) + min;
}

/**
 * Generate a shortened key from a given string
 * Uses sha256 to has the input string and then convert to hex.
 * Converting the hex into an integer, it then seeds a random number generator to generate a number,
 *  at most zzzzzz in hex adding the min 7 character hex value to ensure we will have 7 characters.
 * This will always lead with a `1` since the min value for 7 characters is 1000000,
 *  (adding the max 6 character value zzzzzz would still just yeild 1zzzzzz)
 *  then convert our integer back into a string from base36 and slice the first character to ensure it's 6 characters
 *
 * @param input String to hash and generate a shortened key from
 * @returns 6 character string
 */
export function generateShortenedKey(input: string) {
  // Generate a hash of the link to make it unique
  const hash = createHash('sha256')
    .update(encodeURIComponent(input))
    .digest('hex');
  const seed = parseInt(hash, 16);
  const randomBase36 = getRandomIntFromSeed(1, MAX_6_CHAR_INT, seed);

  const intToBase36 = MIN_7_CHAR_INT + randomBase36;

  // value is 7 characters long, so slice the first character
  const shortKey = intToBase36.toString(36).slice(1);
  return shortKey;
}
